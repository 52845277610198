import type {
  AdyenCardPaymentMethodConfig,
  ApplePayPaymentMethodConfig,
  GooglePaymentMethodConfig,
  KlarnaPaymentMethodConfig,
  OneyPaymentMethodConfig,
  PaymentMethodConfig,
  SquarePaymentMethodConfig,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

export const isValidAdyenCardPaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is AdyenCardPaymentMethodConfig => {
  return !!config && 'publicKey' in config && 'environment' in config
}

export const isValidGooglePayPaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is GooglePaymentMethodConfig => {
  return (
    !!config &&
    'type' in config &&
    'parameters' in config &&
    'environment' in config &&
    'merchantInfo' in config &&
    'tokenizationSpecification' in config
  )
}

export const isValidApplePayPaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is ApplePayPaymentMethodConfig => {
  return (
    !!config &&
    'merchantCapabilities' in config &&
    'merchantIdentifier' in config &&
    'supportedNetworks' in config
  )
}

export const isValidKlarnaPaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is KlarnaPaymentMethodConfig => {
  return !!config && 'libraryUrl' in config && 'clientId' in config
}

export const isValidSquarePaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is SquarePaymentMethodConfig => {
  return !!config && 'libraryUrl' in config && 'mpid' in config
}

export const isValidOneyPaymentMethodConfig = (
  config?: PaymentMethodConfig,
): config is OneyPaymentMethodConfig => {
  return !!config && 'merchantGuid' in config
}
