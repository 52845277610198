import type {
  PaymentGroup,
  PaymentMethodCode,
  PaymentNetwork,
} from '@backmarket/http-api/src/api-specs-payment/payment'

export const PAYMENT_ICON_PATHS = {
  // '-v3' was added to the folder name to reset the icon cache.
  // (Assets are uploaded with lifetime cache, so renaming is the only way)
  GROUPS: '/img/payment/groups-v3',
  METHODS: '/img/payment/methods-v4',
  NETWORKS: '/img/payment/networks-v4',
} as const

export type PaymentGroupIconPath =
  `${(typeof PAYMENT_ICON_PATHS)['GROUPS']}/${PaymentGroup}.svg`

export type PaymentMethodIconPath =
  `${(typeof PAYMENT_ICON_PATHS)['METHODS']}/${PaymentMethodCode}.svg`

type PaymentNetworkIconPath =
  `${(typeof PAYMENT_ICON_PATHS)['NETWORKS']}/${PaymentNetwork}.svg`

export type PaymentIconPath =
  | PaymentGroupIconPath
  | PaymentMethodIconPath
  | PaymentNetworkIconPath
