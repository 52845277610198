import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'
import type { LooseAutocomplete } from '@backmarket/utils/types/LooseAutocomplete'

export const PaymentErrorTypes = {
  USER_CANCEL_ACTION: '/errors/payment/user/cancel-action',
  USER_INVALID_INPUT: '/errors/payment/user/invalid-input',
  NETWORK_ERROR: '/errors/payment/network-error',
  UNEXPECTED_PAYMENT_ERROR_TYPE: '/errors/payment/unexpected',
} as const
export type PaymentErrorTypes = UnionOfPropertyTypes<typeof PaymentErrorTypes>

export const PaymentErrorSource = {
  UNKNOWN: 'UNKNOWN',
  FRONT_LIBRARY_PROCESSOUT: 'FRONT_LIBRARY_PROCESSOUT',
  FRONT_GENERIC: 'FRONT_GENERIC',
  API_PROBLEM: 'API_PROBLEM_GENERIC',
} as const
export type PaymentErrorSource = UnionOfPropertyTypes<typeof PaymentErrorSource>

type ExtendablePaymentErrorTypes = LooseAutocomplete<
  PaymentErrorTypes | `${PaymentErrorTypes}/${string}`
>

type ExtendablePaymentErrorSource = LooseAutocomplete<PaymentErrorSource>

export class PaymentError extends Error {
  public type: ExtendablePaymentErrorTypes

  public source: ExtendablePaymentErrorSource

  constructor(
    message: string,
    options: {
      cause?: unknown
      source?: ExtendablePaymentErrorSource
      type?: ExtendablePaymentErrorTypes
    },
  ) {
    super(message, options)

    if (this.cause !== options?.cause) {
      // "Polyfill" error cause for older browsers https://caniuse.com/mdn-javascript_builtins_error_cause
      this.cause = options?.cause
    }

    this.type = options?.type ?? PaymentError.getDefaultType(this.cause)
    this.source = options?.source ?? 'UNKNOWN'
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDefaultType(cause: unknown) {
    // TODO: [PAYIN-3616] handle network error
    // if (cause?.code === (AxiosError?.ERR_NETWORK ?? 'ERR_NETWORK')) {
    //   return PAYMENT_ERROR_TYPES.NETWORK_ERROR
    // }

    return PaymentErrorTypes.UNEXPECTED_PAYMENT_ERROR_TYPE
  }
}
